
import { Component, Prop, Vue } from 'vue-property-decorator';
// eslint-disable-next-line import/order, import/extensions
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridVue } from 'ag-grid-vue';
import { getComponent, getView, sleep } from '@/utils/helpers';
import metaDataModule from '@/store/modules/metaDataModule';
import FuzzySearch from 'fuzzy-search';
import accountModule from '@/store/modules/accountModule';
import gptModule from '@/store/modules/gptModule';
import CustomHeader from '@/views/agGrid/aiGroupsHeader.vue';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    TasqLoader: () => getComponent('loaders/TasqLoader'),
    ActionBuilderForm: () => getView('ActionBuilderForm'),
    FormFilterPopup: () => getView('FormFilterPopup'),
    ConfirmPopup: () => getComponent('tasqsCommon/ConfirmPopup'),
    agColumnHeader1: CustomHeader,
    FormDetailPopup: () => getView('FormDetailPopup'),
    AgGridVue,
  },
})
export default class GroupDataView extends Vue {
  @Prop({ type: Array, required: true, default: [] }) group?: any;

  context: any = null;

  searchQuery = '';

  columnDefs: any = [];

  formCategories: any = [];

  formArtificialLiftTypes: any = [];

  deleteFormPopup = false;

  formID = '';

  showFormFilter = false;

  showFormDetail = false;

  formFilter: any = {
    categoriesSelected: [],
    titlesSelected: [],
    lastEditorSelected: [],
    lastEditStartDate: '',
    lastEditEndDate: '',
  };

  defaultColDef = {
    initialWidth: 250,
    sortable: true,
    filter: true,
    editable: false,
    autoHeight: true,
    cellStyle: {
      borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
      overflow: 'visible',
      'white-space': 'normal',
    },
    resizable: true,
  };

  rowData: any = [];

  cellClick() {
    // console.log(e);
  }

  getRowNodeId: any = null;

  gridApi: any = null;

  gridColumnApi: any = null;

  dataLoading = false;

  async created() {
    this.fetchActions();
  }


  randomAssetName(level: String, asset_name: String) {
    const alphabet = 'abcdefghijklmnopqrstuvwxyz';
    const randomLetter = alphabet[Math. floor(Math. random() * alphabet. length)]
    if (asset_name.length % 2 == 0) {
      return level.toLowerCase() == "well"  ? `Well ` + asset_name.substring(0,2).toUpperCase() : `Pad ` + asset_name.substring(0,2).toUpperCase();
    } else {
      return level.toLowerCase() == "well"  ? `Well ` + asset_name.substring(0,1).toUpperCase() : `Pad ` + asset_name.substring(0,1).toUpperCase();
    }

  }



  formatDate(date) {
    const options: any = { month: 'short', day: 'numeric', year: 'numeric' };

    let dateObj = new Date(date);

    let startFormatted = dateObj.toLocaleDateString('en-US', options);

    return `${startFormatted}`;
  }

  headers = [
    'WELL Name',
    'DEFERMENT',
    'Route',
    'CYCLE TIME',
    'LAST ACTIVITY TIME',
    '',
  ];
  columns = [
    { key: 'wellName', editable: false },
    { key: 'defermentValue', editable: false },
    { key: 'route', editable: true },
    { key: 'cycleTime', editable: true },
    { key: 'lastThirtyDaysCount', editable: false },
    { key: 'copyShare', editable: false },
  ];

  async fetchActions() {
    console.log(this.group);
    this.dataLoading = true;
    const group = this.group.map((t,i) => {
      const latestLastAction = gptModule.getLatestLastAction(t.wellName);
      return {
        wellName:  this.randomAssetName("well",  t.wellName) ,
        defermentValue: t.defermentValue
          ? t.defermentValue + ' ' + t.defermentUnit
          : '-',
        route: 'Route' + ' ' + i,
        cycleTime: t.dateAgoText || '-',
        lastThirtyDaysCount: latestLastAction
          ? this.formatDate(latestLastAction.point_time)
          : '-',
      };
    });
    console.log(group);
    this.rowData = group;
    this.dataLoading = false;
  }
}
